:root{
  --primary_background: #3f325c;
  --secondary_background: #f9f9f9;
  --primary_button_color: #bb9cd8;
  --secondary_button_color: #9b5aac;
  --button_shadow: #a09797;
  --text_primary_accent: #F9F9F9;
  --text_secondary_accent: #192A51;
  --notfound_primary_text: #9379c0;
  --notfound_secondary_text: #7c69a0;
  --about_border: #281e3d;
  --aboutJohn_primary_color: #bb9cd8;
  --aboutMainChar_color: #120b20BB;
  --aboutNyan_primary_color: #f59dd0;
  --aboutNyan_secondary_color: #770d36;
  --aboutWoofer_primary_color: #f8b689;
  --aboutWoofer_secondary_color: #a7453e;
  --aboutHops_primary_color: #f3e389;
  --aboutHops_secondary_color: #b3761a;
  --stage_primary_color: #24152e;
  --stage_secondary_color: #120b20;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

*{
  font-family: Arial;
  color: var(--text_secondary_accent);
  max-width: 100%;
  margin: 0;
}
html{
  
  background-color: var(--primary_background);
}
.body{
  background-color: var(--primary_background);
  padding-bottom: 100px;
}
.body{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */}
*::-webkit-scrollbar{display: none;  /* Safari and Chrome */}

/*404 PAGE:*/

.notfound{
  margin: 15% 0 0 0;
  align-items: center;
  text-align: center;
  animation: 2s lineUp ease-out;
  }
  @keyframes lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

.notfound h1{
  padding: 30px;
  color: var(--notfound_primary_text);
  font-size: 60px;
}

.notfound p{
  font-size: 30px;
  margin: 0 0 30px 0;
  color: var(--notfound_secondary_text);
}

.notfound a{
  padding: 5px;
  border-radius: 16px;
  background-color: var(--primary_button_color);
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  color: var(--text_secondary_accent);
}

.notfound a:hover{
  background-color: var(--secondary_background);
  box-shadow: 2px 2px 5px var(--primary_button_color);
}

/*HOME BANNER*/

.home img{
  position: relative;
  left:0;
  background-image: url("./img//BG0.gif");
  width: 100%;
  border-bottom: 4px solid var(--secondary_background);
}

.home h1{
  text-align: center;
  color: var(--secondary_background);
  font-size: clamp(20px,10vw,50px);
  font-weight: bold;
}

.header{
  background-color: var(--stage_primary_color);
  box-shadow: 0 0 15px var(--about_border);
  display:flex;
  border-radius: 20px;
  flex-direction: column;
  text-align: center;
  margin: 1em;
  padding: 2em;
}

.header p{
  color: var(--secondary_background);
  font-size: 20px;
}

/*NAVBAR:*/

.navbar{
  padding-top: 1em;
  background-color: var(--secondary_background);
  text-align: center;
  position: relative;
  width: 100%;
  padding-bottom: 0.1em;
  z-index: 999;
}

.Logo{
  margin: 0.5rem;
}

.Pages{
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background-color: var(--secondary_background);
  width: 100%;
  transform: scale(1,0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

.Pages ul{
  margin: 0;
  padding: 0;
  list-style: none;
}

.Pages a{
  display: flex;
  padding-bottom: 0.75em;
  padding-top: 0.75em;
  padding-left: 25px;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
  table-layout:fixed;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  overflow-x: clip;
}
.Pages li:has(> a.active){
  background-color: var(--primary_button_color);
}
.Pages a.active{
  color:var(--text_primary_accent);
}

.navbar_toggle{display: none;}
.navbar_toggle:checked ~ .Pages{
  transform: scale(1,1);
}
.navbar_toggle_label{
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0.5em;
  height: 100%;
  display: grid;
  align-items: center;
}
.menu_button{
  grid-column: 1;
  grid-row: 1;
  margin: 1/2;
  height: 2em;
  width: 2em;
  position: relative;
  opacity:1;
  transition: opacity 200ms ease-in-out;
}

.close_button{
  grid-column: 1;
  grid-row: 1;
  height: 1.7em;
  width: 1.7em;
  position: relative;
  opacity:0;
  transition: opacity 200ms ease-in-out;
}
.navbar_toggle:checked ~ label .close_button{
  display: block;
  opacity:1;
  transition: opacity 200ms ease-out 50ms;
}
.navbar_toggle:checked ~ label .menu_button{
  opacity:0;
  transition: opacity 200ms ease-in-out;
}
.navbar_toggle:checked ~ .Pages a{
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 800px) {
  .navbar_toggle_label{display: none;}
  .Pages{
    all: unset;
    display: flex;
    justify-content: center;
  }
  .Pages a{
    display: inline;
    margin-left: 0;
    opacity: 1;
    font-weight: bold;
    padding: 0.6em;
  }
  .Pages ul{
    margin: 1em 0; 
  }
  .Pages li{
    all:unset;
    margin: 0.8rem;
  }
  .Pages li:has(> a.active){
    background-color: inherit;
  }
  .Pages a.active{
    color: var(--text_primary_accent);
    font-weight: bold;
    background-color: var(--primary_button_color);
    box-shadow: 2px 2px 5px var(--button_shadow);
    border-radius: 2rem;
  }
  .Pages a:hover{
    color: var(--text_primary_accent);
    font-weight: bold;
    background-color: var(--secondary_button_color);
    box-shadow: 2px 2px 5px var(--button_shadow);
    border-radius: 2rem;
    transition: background-color 250ms;
  }
}

body div h2{
  color:var(--text_primary_accent);
  text-align: center;
} 

/*FOOTER:*/

.footer{
  padding-top: 1%;
  position: fixed;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  background-color: var(--secondary_background);
  border-top: 3px solid var(--primary_button_color) ;
  white-space: nowrap;
  left: 0;
  bottom: 0;
}

.footer p{
  background-color: inherit;
  text-align: center;
  margin: 5px 0 0 0;
  color: #682e60;
}

.footer .links{
  background-color: inherit;
  display: block;
}

.footer .links ul{
  background-color: inherit;
  text-align: center;
  padding:0 0 0 0;
}

.footer .links ul li{
  display: inline-block;
  list-style-type: none;
  background-color: inherit;
  padding:2px;
  white-space: nowrap;
}

.footer .links ul li a{
  background-color: inherit;
}

.footer .links ul li a img{
  width: 40px;
  padding:5px;
  background-color: inherit;
}

.footer .links ul li a img:hover{
  border-radius: 20px;
  background-color: var(--primary_button_color);
  box-shadow: 2px 2px 5px var(--primary_background);
  transition: background-color 250ms, box-shadow 250ms;
}

@media screen and (max-width:300px) {
  .footer p{
    font-size: small;
  }  
}

/*ABOUT PAGE:*/

div.about{
  text-align: center;
  padding: 20px;
}

.john{
  padding: 10px;
  margin-inline: 2%;
  margin-bottom: 20px;
  background-color: var(--aboutJohn_primary_color);
  border-radius: 20px;
  border-bottom: 5px solid var(--about_border);
}

.profilePic0{
  padding-bottom: 10px;
}

.profilePic0 img{
  width: 13rem;
  border: 8px inset var(--about_border);
  border-radius: 100%;
}

.profileInfo0{
  background-color: var(--aboutMainChar_color);
  border-radius: 30px 30px 0 0;
}

.profileInfo0 h2,
.profileInfo0 p span,
.profileInfo1 h2,
.profileInfo1 p span,
.profileInfo2 h2,
.profileInfo2 p span,
.profileInfo3 h2,
.profileInfo3 p span{
  color: var(--text_primary_accent);
  font-weight: bold;
}

.profileInfo0 p,
.profileInfo1 p,
.profileInfo2 p,
.profileInfo3 p{
  color: var(--text_primary_accent);
}

.bio0 {
  background-color: var(--aboutMainChar_color);
  border-radius: 0 0 20px 20px;
  padding: 8px;
}

.bio0 p,
.bio1 p,
.bio2 p,
.bio3 p{
  text-align: left;
  text-indent: 2ch;
  color: var(--text_primary_accent);
}

hr{
  height: 2px;
  background-color: var(--about_border);
  border: none;
}

.about h1{
  margin-block: 20px;
  padding: 0 30px 0 30px;
  color: var(--text_primary_accent);
  display:inline-block;
  text-align: center;
  font-size: 40px;
  background-color: var(--aboutMainChar_color);
  border-radius: 40px;
}

.nyan{
  padding: 10px;
  margin-inline: 1%;
  margin-bottom: 20px;
  background-color: var(--aboutNyan_primary_color);
  border-radius: 30px;
  align-items: center;
  border-bottom: 5px solid var(--about_border);
}

.nyan img{
  margin: 10px auto;
  width: clamp(13rem,50vw,20rem);  
}

.profileInfo1{
  background-color: var(--aboutNyan_secondary_color);
  border-radius: 30px 30px 0 0;
}

.bio1 {
  background-color: var(--aboutNyan_secondary_color);
  border-radius: 0 0 20px 20px;
  padding: 8px;
}

.woofer{
  padding: 10px;
  margin-inline: 1%;
  margin-bottom: 20px;
  background-color: var(--aboutWoofer_primary_color);
  border-radius: 30px;
  align-items: center;
  border-bottom: 5px solid var(--about_border);
}

.woofer img{
  margin: 10px auto;
  width: clamp(13rem,50vw,20rem);  
}

.profileInfo2{
  background-color: var(--aboutWoofer_secondary_color);
  border-radius: 30px 30px 0 0;
}

.bio2 {
  background-color: var(--aboutWoofer_secondary_color);
  border-radius: 0 0 20px 20px;
  padding: 8px;
}

.hops{
  padding: 10px;
  margin-inline: 1%;
  margin-bottom: 20px;
  background-color: var(--aboutHops_primary_color);
  border-radius: 30px;
  align-items: center;
  border-bottom: 5px solid var(--about_border);
}

.hops img{
  margin: 10px auto;
  width: clamp(13rem,50vw,20rem);   
}

.profileInfo3{
  background-color: var(--aboutHops_secondary_color);
  border-radius: 30px 30px 0 0;
}

.bio3 {
  background-color: var(--aboutHops_secondary_color);
  border-radius: 0 0 20px 20px;
  padding: 8px;
}

@media screen and (min-width: 800px) {
  .john{
    width: 709.625px;
  }
  .meSection{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 675px) {
  .nyan,
  .woofer,
  .hops{
    margin-inline: 0;
    width:602.312px;
  }
  .charactersList{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1500px) {
  .nyan,
  .woofer,
  .hops{
    margin-inline: 0;
    width: 400px;
    height:720px;
  }
  .charactersList{
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center;
  }
  .bio1,
  .bio2,
  .bio3{
    height:280px;
  }
}

.platoon{
  margin:2%;
  padding: 10px;
  text-align: center;
}

.platoon img{
  width:clamp(100rem,50vw,100rem);
  border-radius: 30px;
}

/*ART:*/

.art{
  text-align: center;
  width: 100%;
}

.categories{
  list-style-type: none;
  padding-inline:0;
}
.categories li{
  display: inline-block;
}

.artGroup{
  background-color: var(--primary_button_color);
  padding: 20px;
  width: clamp(7rem,50vw,20rem);
  height: auto;
  margin: 10px;
  border: 2px solid var(--about_border);
  border-radius: 10%;
}

.artGroup:hover{
  background-color: var(--secondary_background);
  border: 2px solid var(--secondary_background);
}

.categories a{
  text-decoration: none;
}

.categories img{
  width: auto;
  height: clamp(2rem,50vw,18rem);
  background-color: var(--about_border);
  border-radius: 10%;
}

.categories p{
  font-weight: bold;
  text-decoration: inherit;
  font-size: 20px;
  color: var(--text_primary_accent);
  border-radius: 30px;
  background-color: var(--primary_background);
}

/*GALLERY*/

.artfight{
  display: flex;
  justify-content: center;
}
.artfightLink img{
  width: 100px;
}


.stage{
  margin: 20px 8% 10px;
  background-color: var(--stage_primary_color);
  height: 520px;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 0 15px var(--about_border);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image{
  height: clamp(13rem,50vw,20rem);
  width: auto;
  text-align: center;
  margin-block: 20px;
}

img.art{
  width: auto;
  height: clamp(13rem,50vw,20rem);
}

.imageInfo{
  background-color: var(--primary_background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.year:before{
  content:"Year Created: ";
  font-weight: bold;
}

.imageInfo .title,
.imageInfo .year,
.imageInfo .description{
  text-align: center;
  padding: 10px;
  color: var(--text_primary_accent);
}

.imageNote{
  text-align: center;
  font-size: small;
  font-style:italic;
  margin-top: auto;
  padding-bottom: 5px;
  color: var(--aboutJohn_primary_color);
}

.gallery {
  margin: 20px 8% auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.entry{
  background-color: var(--primary_button_color);
  margin-block: 0;
  width: clamp(2rem,100vw,200px);
  text-align: center;
  border-radius: 20px;
}

.preview{
  background-color: var(--aboutMainChar_color);
  width: clamp(2rem,50vw,10rem);
  margin-block: 10px;
}

@media screen and (min-width: 880px) {
  .stage{
    width:720px;
  }
  .stageContainer{
    display: flex;
    justify-content: center;
  }
}

/*PAGE BUTTONS:*/

.pageButton{
  margin: 10px 2% 10px;
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 30px;
  background-color: var(--stage_primary_color);
}
.PrevPage,
.NextPage{
  width: clamp(1em,50vh,5em);
  border-radius: 100%;
}
.pageNum{
  display: flex;
  margin-block: 6px;
  flex-direction: column;
  text-align: center;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: var(--primary_background);
}
.pageNumTitle,
.pageNumValue{
  color: var(--text_primary_accent);
  font-size: 25px;
}

@media screen and (min-width:500px) {
  .pageButton{
    width: 480px;
  } 
  .pageContainer{
    display: flex;
    justify-content: center;
  }
}

/*MUSIC*/

.nbs{
  display: flex;
  justify-content: center;
}

.NBSLogo{
  width: 400px;
}

.stageV{
  margin: 20px 8% 10px;
  background-color: var(--stage_primary_color);
  height: auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 15px var(--about_border);
  display: flex;
  flex-direction: column;
  align-items: center;
  }


.video iframe {
  width: clamp(16rem,50vw,32rem);
  height: clamp(9rem,50vw,18rem);
  box-shadow: 2px 2px 5px var(--about_border);
  margin-block: 10px;
}

.videoInfo{
  background-color: var(--primary_background);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.videoInfo .title{
  padding: 10px;
  background-color: var(--primary_background);
  border-radius: 36px;
  font-size: 20px;
}

.videoInfo .game,
.videoInfo .origartist{
  text-align: center;
  padding: 5px;
  color: var(--text_primary_accent);
}

.videoInfo .game::before{
  content:"From: ";
  font-weight: bolder;
}

.videoInfo .origartist::before{
  content: "Original Artists: ";
  font-weight: bolder;
}
.nbsDownload{
  display: flex;
  justify-content: center;
}

.videoNote{
  border-radius: 10px;
  padding-inline: 10px;
  background-color: var(--primary_button_color);
  text-align: center;
  margin-top: auto;
  margin-bottom: 10px;
}

.videoInfo a{
  text-decoration: none;
}

.videoNote strong{
  font-weight: bold;
  color: var(--text_secondary_accent);
}

.videoNote:hover{
  background-color: var(--secondary_background);
  box-shadow: 2px 2px 5px var(--primary_button_color);
}

.Vlist{
  margin-block: 20px;
  margin-inline: 10px ;
  padding-block: 20px;
  box-shadow: 0 0 15px var(--stage_secondary_color);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--stage_secondary_color);
  gap: 5px;
  }


.Ventry{
  width: clamp(15rem,50vw,20rem);
  height:auto;
}

.Ventry:hover{
  box-shadow: 0 0 20px var(--secondary_background);
}

@media screen and (min-width: 1020px) {
  .stageV{
    width: 815px;
  }
  .stageVContainer{
    display: flex;
    justify-content: center;
  }  
}

/*COMMISSION*/

/*RESUME:*/

.resume{
  margin: 20px 8% 10px;
  align-items: center;
  text-align: center;
}

.resume iframe{
  width: 100%;
  height: clamp(300px,50vw,600px);
}

.resumeExtern{
  display: flex;
  justify-content: center;
}

.resumeButton p{
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  width: 232px;
  background-color: var(--primary_button_color);
}

.resumeButton a{
  text-decoration: none;
}

.resumeButton p:hover{
  background-color: var(--secondary_background);
  box-shadow: 2px 2px 5px var(--primary_button_color);
}

.resumeLink{
  font-weight: bold;
  color: var(--text_secondary_accent);
}

@media screen and (min-width:925px) {
  .resume iframe{
    width: 763px;
    height: 600px
  }
}